import { User } from "../../types";
import { apiClient } from "./ApiClient";

interface TokenResponseData {
  accessToken: string
}

interface SignupMethodParams {companyCode?: string, email: string, password: string, firstName: string, lastName: string}

type SignupMethod = (params: SignupMethodParams) => Promise<TokenResponseData>

export const signup: SignupMethod = async (signupParams) => {
  const response = await apiClient.post<TokenResponseData>('/auth/signup', signupParams);

  return response.data;
}

export const login = async (email: string, password: string): Promise<TokenResponseData> => {
  const response = await apiClient.post<TokenResponseData>('/auth/login', {
    email,
    password
  });

  return response.data;
}

export const logout = async () => {
  await apiClient.post<TokenResponseData>('/auth/logout');
}

export const forgotPassword = async (email: string): Promise<void> => {
  await apiClient.post('/auth/passwords/forgot', {
    auth: {email},
  });
}

export type ResetPasswordPayload = {
  token: string,
  password: string
}

export const resetPassword = async (payload: ResetPasswordPayload): Promise<void> => {
  await apiClient.post('/auth/passwords/reset', {
    auth: payload
  });
}

export const getInvite = async (invite: string): Promise<User> => {
  const response = await apiClient.get('/auth/invite', {
    params: {
      invite
    },
  });

  return response.data;
}