import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Statistic } from 'antd'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { CompaniesApi } from '../api'
import { Analytics } from '../../types'
import { useAuth } from '../auth'
import moment from 'moment'

export const AnalyticsPage: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [analytics, setAnalytics] = useState<Analytics>()

  const dayMap = {
    Mon: t('general.daysOfWeek.monday'),
    Tue: t('general.daysOfWeek.tuesday'),
    Wed: t('general.daysOfWeek.wednesday'),
    Thu: t('general.daysOfWeek.thursday'),
    Fri: t('general.daysOfWeek.friday'),
    Sat: t('general.daysOfWeek.saturday'),
    Sun: t('general.daysOfWeek.sunday'),
  }

  const fetchAnalytics = async () => {
    setLoading(true)
    const response = await CompaniesApi.getAnalytics(user?.company.id || '')
    setLoading(false)
    setAnalytics(response)
  }

  const membershipsData = useMemo(() => {
    if (!analytics) return []
    return Object.entries(analytics.membershipCounts)
      .filter(([_, value]) => value !== 0)
      .sort(([_, valueA], [__, valueB]) => valueB - valueA)
      .map(([key, value]) => ({
        name: key,
        value,
      }))
  }, [analytics])

  const entriesByDay = useMemo(() => {
    if (!analytics) return []
    return Object.entries(analytics.entriesByDay)
      .map(([key, value]) => ({
        name: key,
        value,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [analytics])

  const entriesByHour = useMemo(() => {
    const values: { [key: string]: { name: string; value: number }[] } = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    }
    if (!analytics) return {}

    analytics.entriesByHour.forEach(({ key, value }) => {
      // convert utc from server to local time
      const date = moment.utc(key, 'ddd HH:mm').local().format('ddd HH:mm')
      const [dayInWeek, hourAndMinutes] = date.split(' ')
      const dayName = dayMap[dayInWeek as keyof typeof dayMap]
      const hour = hourAndMinutes.split(':')[0]
      values[dayName.toLowerCase()].push({ name: hour, value })
    })

    return values
  }, [analytics])

  useEffect(() => {
    fetchAnalytics()
  }, [])

  return (
    <Card title={t('analytics.title', 'Analytics')} loading={loading}>
      <Row gutter={16}>
        <Col span={6}>
          <Card bordered={true}>
            <Statistic title="Total users" value={analytics?.users} />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={true}>
            <Statistic
              title="Active users"
              value={analytics?.activeUsers}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Card bordered={true} title={t('analytics.membershipCounts')}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart width={600} height={250} data={membershipsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis dataKey="value" />
                <Bar dataKey="value" fill="#003f5c">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Card bordered={true} title={t('analytics.entriesByDay')}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart width={600} height={250} data={entriesByDay}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis dataKey="value" />
                <Bar dataKey="value" fill="#003f5c">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Card bordered={true} title={t('analytics.entriesByHour')}>
            {Object.keys(entriesByHour).map((day) => (
              <>
                <h3>{t(`general.daysOfWeek.${day}`)}</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart width={600} height={100} data={entriesByHour[day]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="value" />
                    <Bar dataKey="value" fill="#003f5c">
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </>
            ))}
          </Card>
        </Col>
      </Row>
    </Card>
  )
}
