// Component that renders image of user avatar

import React from 'react'
import { Avatar } from 'antd'
import { User } from '../../../types'

type UserAvatarProps = {
  user: User
  height?: number
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ user, height = 64 }) =>
  user.avatarUrl ? (
    <img
      src={user.avatarUrl}
      alt={user.email}
      style={{ maxHeight: `${height}px` }}
    />
  ) : (
    <Avatar size={height}>
      {user.name
        .split(' ')
        .map((n) => n[0])
        .join('')}
    </Avatar>
  )
