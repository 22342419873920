import { GroupProgram } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<GroupProgram[]> = async () => {
  const response = await apiClient.get<GroupProgram[]>('/group_programs')
  return response.data
}

export const get: (id: string) => Promise<GroupProgram> = async (id) => {
  const response = await apiClient.get<GroupProgram>(`/group_programs/${id}`)
  return response.data
}

export const create: (data: {
  groupProgram: Omit<GroupProgram, 'id'>
}) => Promise<GroupProgram> = async (data) => {
  const response = await apiClient.post<GroupProgram>('/group_programs', data)
  return response.data
}

export const update: (
  id: string,
  data: { groupProgram: Omit<GroupProgram, 'id'> }
) => Promise<GroupProgram> = async (id, data) => {
  const response = await apiClient.put<GroupProgram>(`/group_programs/${id}`, data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<GroupProgram>(`/group_programs/${id}`)
  return response.data
}
