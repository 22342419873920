import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Card, Col, List, Popconfirm, Row, Space } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

import { useGroupProgram } from './hooks/useGroupProgram'
import { GroupProgramSchedule, ManageParticipantsModal } from './components'
import { getDayOfWeekString } from '../helpers'
import { Group } from '../../types'

export const GroupProgramShowPage: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { groupProgram, loading, refetch, deleteGroupProgram } =
    useGroupProgram(id)
  const [participantsModalOpen, setParticipantsModalOpen] =
    React.useState(false)
  const [selectedGroup, setSelectedGroup] = React.useState<Group>()

  const openParticipantsModal = (group: Group) => {
    setSelectedGroup(group)
    setParticipantsModalOpen(true)
  }

  const closeParticipantsModal = () => {
    setParticipantsModalOpen(false)
    setSelectedGroup(undefined)
    refetch()
  }

  return (
    <>
      <Card
        title={groupProgram?.name}
        extra={
          <Space>
            <Link to={`/groupPrograms/${groupProgram?.id}/edit`}>
              <Button icon={<EditOutlined />}>{t('actions.edit')}</Button>
            </Link>
            <Popconfirm
              title={`${t('general.areYouSure')}`}
              onConfirm={() =>
                groupProgram && deleteGroupProgram(groupProgram.id)
              }
              okText={`${t('general.yes')}`}
              cancelText={`${t('general.no')}`}
            >
              <Button key="delete" danger icon={<DeleteOutlined />}>
                {t('actions.delete')}
              </Button>
            </Popconfirm>
          </Space>
        }
        loading={loading || !groupProgram}
      >
        <Row gutter={16}>
          <Col span={12}>
            <h4 style={{ fontWeight: 600 }}>{t('general.description')}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(groupProgram?.description || ''),
              }}
            ></div>
            <h3 style={{ fontWeight: 600 }}>
              {t('groupPrograms.groups.groups')}
            </h3>
            {groupProgram?.groups.map((group) => (
              <List
                key={group.id}
                header={
                  <h4 style={{ fontWeight: 600, margin: 0 }} key={group.id}>
                    {group.name} - {group.groupSignups?.length || 0}/
                    {group.maxParticipants} {t('groupPrograms.participants')}
                  </h4>
                }
                footer={
                  <Button
                    onClick={() => openParticipantsModal(group)}
                    type="default"
                  >
                    {t('groupPrograms.manageParticipants')}
                  </Button>
                }
                style={{ marginBottom: 16 }}
                bordered
                dataSource={group.groupSlots}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${getDayOfWeekString(item.dayOfWeek, t)}`}
                      description={`${item.startTime} - ${item.endTime}`}
                    />
                  </List.Item>
                )}
              />
            ))}
          </Col>
          <Col span={12}>
            <GroupProgramSchedule
              groupPrograms={groupProgram ? [groupProgram] : []}
            />
          </Col>
        </Row>
      </Card>
      {selectedGroup && (
        <ManageParticipantsModal
          open={participantsModalOpen}
          group={selectedGroup}
          onClose={closeParticipantsModal}
        />
      )}
    </>
  )
}
