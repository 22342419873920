import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalProps, List, Space, Alert } from 'antd'
import { Group } from '../../../types'
import { DeleteButton, UserSelect } from '../../../components'
import { GroupSignupsApi } from '../../api'
import { UserAvatar } from '../../users'
import { ApiErrorResponse } from '../../api/ApiErrors'

type ManageParticipantsModalProps = ModalProps & {
  group: Group
  onClose: () => void
}

export const ManageParticipantsModal: React.FC<
  ManageParticipantsModalProps
> = ({ group, onClose, ...modalProps }) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<ApiErrorResponse['errors']>()
  const [selectedUserId, setSelectedUserId] = useState<string>()
  const [groupSignups, setGroupSignups] = useState(group.groupSignups || [])

  const onSelectedUserChange = (selectedValue: { value: string }) => {
    setSelectedUserId(selectedValue.value)
  }

  const fetchGroupSignups = async () => {
    const newGroupSignups = await GroupSignupsApi.index({ groupId: group.id })
    setGroupSignups(newGroupSignups)
  }

  const createGroupSignup = async () => {
    if (!selectedUserId) return
    try {
      await GroupSignupsApi.create({
        groupSignup: {
          groupId: group.id,
          userId: selectedUserId,
        },
      })
      await fetchGroupSignups()
    } catch (error: any) {
      if (error.response.data.errors) {
        setErrors(error.response.data.errors as ApiErrorResponse['errors'])
      }
    }
  }

  const deleteGroupSignup = async (id: string) => {
    try {
      await GroupSignupsApi.destroy(id)
      await fetchGroupSignups()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      title={t('groupPrograms.manageParticipants')}
      {...modalProps}
      onCancel={onClose}
      onOk={onClose}
    >
      <List
        rowKey="id"
        dataSource={groupSignups}
        header={`${t('groupPrograms.participants')}(${groupSignups.length}/${
          group.maxParticipants
        })`}
        footer={
          <Space.Compact block style={{ alignContent: 'center' }}>
            <UserSelect onChange={onSelectedUserChange} />{' '}
            <Button onClick={createGroupSignup}>
              {t('groupPrograms.addParticipant')}
            </Button>
          </Space.Compact>
        }
        renderItem={(item) => (
          <List.Item key={item.id} style={{ alignItems: 'center' }}>
            <List.Item.Meta
              title={item.user.name}
              avatar={<UserAvatar user={item.user} height={32} />}
              style={{ alignItems: 'center' }}
            />
            <div>
              <DeleteButton onDelete={() => deleteGroupSignup(item.id)} />
            </div>
          </List.Item>
        )}
        locale={{ emptyText: t('groupPrograms.noParticipants') }}
      />
      {errors?.map((error, index) => (
        <Alert key={index} type="error" message={t(`errors.${error.type}`)} />
      ))}
    </Modal>
  )
}
