import { DeleteOutlined } from '@ant-design/icons'
import { Card, Button, Popconfirm } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { GroupProgramForm } from './forms/GroupProgramForm'
import { useGroupProgram } from './hooks/useGroupProgram'

export const GroupProgramEditPage: React.FC = () => {
  const { id = '' } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { groupProgram, loading, refetch, deleteGroupProgram } =
    useGroupProgram(id)

  return (
    <>
      <Card
        key="groupProgramForm"
        title={t('groupPrograms.editGroupPrograms')}
        loading={loading}
        extra={[
          groupProgram && (
            <Popconfirm
              title={`${t('general.areYouSure')}`}
              onConfirm={() =>
                groupProgram && deleteGroupProgram(groupProgram.id)
              }
              okText={`${t('general.yes')}`}
              cancelText={`${t('general.no')}`}
            >
              <Button key="delete" danger icon={<DeleteOutlined />}>
                {t('actions.delete')}
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <GroupProgramForm groupProgram={groupProgram} refetch={refetch} />
      </Card>
    </>
  )
}
