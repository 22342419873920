import React from 'react'
import { Form, Input, Button, Typography, Space, Alert } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from './hooks'
import { Errors } from '../../components'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme'

interface ForgotPasswordFormData {
  email: string
}

export const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation()
  const [success, setSuccess] = React.useState(false)
  const { handleForgotPassword, errors, loading } = useAuth()
  const onFinish = async (values: ForgotPasswordFormData) => {
    await handleForgotPassword(values.email)
    setSuccess(true)
  }

  return (
    <Form<ForgotPasswordFormData> name="forgotPassword" onFinish={onFinish}>
      <Typography.Title level={3}>
        {t('auth.forgotPassword.title')}
      </Typography.Title>
      <Typography.Text>{t('auth.forgotPassword.text')}</Typography.Text>
      <Errors errors={errors} />
      <Form.Item
        style={{ marginTop: theme.spacing.padding }}
        name="email"
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          type="email"
          placeholder="Email"
        />
      </Form.Item>

      {success && (
        <div style={{ marginBottom: theme.spacing.padding }}>
          <Alert message={t('auth.forgotPassword.success')} type="success" />
        </div>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={success}
          loading={loading}
        >
          {t('auth.forgotPassword.submit')}
        </Button>
      </Form.Item>

      <Link to="/auth/login">Login</Link>
    </Form>
  )
}
