import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppError, ChildrenProps } from '../../types'
import { AuthApi, UsersApi } from '../api'
import { ApiErrorResponse, localizeApiErrors } from '../api/ApiErrors'
import { apiClient } from '../api/ApiClient'
import { AuthState } from './types'
import { ROLES } from '../../constants'

export const AuthContext = React.createContext<AuthState>({
  handleSignup: () => Promise.resolve(),
  handleLogin: () => Promise.resolve(),
  handleLogout: () => Promise.resolve(),
  handleForgotPassword: () => Promise.resolve(),
  handleResetPassword: () => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetAuthState: () => {},
  loggedIn: false,
  loading: false,
})

export const AuthProvider: React.FC<ChildrenProps> = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<AuthState['user']>()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState<AppError[]>()

  const isSuperAdmin = user?.role === ROLES.superAdmin.value

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleSignup: AuthState['handleSignup'] = async (params) => {
    setLoading(true)
    setErrors(undefined)
    try {
      const response = await AuthApi.signup(params)
      localStorage.setItem('accessToken', response.accessToken)
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.accessToken}`
      await getCurrentUser()
      navigate('/dashboard')
    } catch (err: any) {
      setLoading(false)
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  const handleLogin: AuthState['handleLogin'] = async (email, password) => {
    setLoading(true)
    setErrors(undefined)
    try {
      const response = await AuthApi.login(email, password)
      localStorage.setItem('accessToken', response.accessToken)
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.accessToken}`
      await getCurrentUser()
      navigate('/dashboard')
    } catch (err: any) {
      setLoading(false)
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  const resetAuthState = () => {
    setLoading(false)
    localStorage.removeItem('accessToken')
    setUser(undefined)
    setLoggedIn(false)
    setErrors(undefined)
  }

  const getCurrentUser = async () => {
    try {
      const response = await UsersApi.getCurrentUser()
      setUser(response)
      setLoading(false)
      setLoggedIn(true)
    } catch (err: any) {
      resetAuthState()
    }
  }

  const handleLogout: AuthState['handleLogout'] = async () => {
    try {
      await AuthApi.logout()
    } catch (err: any) {
      console.error(err)
    }
    resetAuthState()
    apiClient.defaults.headers.common['Authorization'] = null
  }

  const handleForgotPassword: AuthState['handleForgotPassword'] = async (
    email
  ) => {
    try {
      await AuthApi.forgotPassword(email)
    } catch (err: any) {
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  const handleResetPassword: AuthState['handleResetPassword'] = async (
    payload
  ) => {
    try {
      await AuthApi.resetPassword(payload)
    } catch (err: any) {
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  const value = {
    user,
    company: user?.company,
    loggedIn,
    handleSignup,
    handleLogin,
    handleLogout,
    handleForgotPassword,
    handleResetPassword,
    resetAuthState,
    loading,
    errors,
    isSuperAdmin,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
