import React from 'react'
import { Form, Input, Button, Typography, Alert } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Link, useSearchParams } from 'react-router-dom'
import { useAuth } from './hooks'
import { Errors } from '../../components'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme'

interface ResetPasswordFormData {
  password: string
}

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation()
  const [success, setSuccess] = React.useState(false)
  const [searchParams] = useSearchParams()
  const { handleResetPassword, errors, loading } = useAuth()

  const onFinish = async (values: ResetPasswordFormData) => {
    await handleResetPassword({
      token: searchParams.get('token') as string,
      password: values.password,
    })
    setSuccess(true)
  }

  return (
    <Form<ResetPasswordFormData> name="resetPassword" onFinish={onFinish}>
      <Typography.Title level={3}>
        {t('auth.resetPassword.title')}
      </Typography.Title>
      <Typography.Text>{t('auth.resetPassword.text')}</Typography.Text>
      <Errors errors={errors} />
      <Form.Item
        style={{ marginTop: theme.spacing.padding }}
        name="password"
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input prefix={<LockOutlined />} type="password" />
      </Form.Item>

      {success && (
        <div style={{ marginBottom: theme.spacing.padding }}>
          <Alert message={t('auth.resetPassword.success')} type="success" />
        </div>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={success}
          loading={loading}
        >
          {t('auth.resetPassword.submit')}
        </Button>
      </Form.Item>

      <Link to="/auth/login">Login</Link>
    </Form>
  )
}
