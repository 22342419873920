import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Descriptions,
  Modal,
  notification,
  Popconfirm,
  Space,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUser } from './hooks'
import { UpdateUserForm } from './'
import { UsersApi } from '../api'
import {
  EditUserMembershipModal,
  UserMembershipStatus,
} from '../userMemberships'
import { formatDate } from '../helpers'
import { isClient } from '../../helpers'
import { EntriesTable } from '../entries'
import moment from 'moment'
import { User } from '../types'
import { EditUserPlansModal } from './components/EditUserPlansModal'

interface UserDetailsPageProps {
  onUpdate?: () => void
}

export const UserDetailsPage: React.FC<UserDetailsPageProps> = ({
  onUpdate,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { user, entries, loading, refetch } = useUser(id || '')
  const [editModalVisible, toggleEditModal] = useState(false)
  const [userPlansModalVisible, toggleUserPlansModal] = useState(false)
  const [userMembershipModalVisible, toggleUserMembershipModal] =
    useState(false)

  const handleSendInvite = async () => {
    try {
      await UsersApi.sendInvite(id || '')
      notification.open({
        message: t('users.sendInviteSuccess'),
        type: 'success',
      })
    } catch (err) {
      console.error(err)
      notification.open({
        message: t('errors.somethingWentWrong'),
        type: 'error',
      })
    }
  }

  const handleUpdateComplete = () => {
    toggleEditModal(false)
    refetch()
    onUpdate?.()
  }

  const handleUserMembershipComplete = () => {
    toggleUserMembershipModal(false)
    refetch()
    onUpdate?.()
  }

  const handleUserPlansUpdateComplete = () => {
    toggleUserPlansModal(false)
    refetch()
    onUpdate?.()
  }

  const handleDeleteUser = async (user: User) => {
    try {
      await UsersApi.destroy(user.id)
      notification.open({
        message: t('general.deleteSuccess'),
        type: 'success',
      })
      onUpdate?.()
      navigate('/users')
    } catch (error) {
      console.error(error)
    }
  }

  if (!user || loading) {
    return <Card loading={true} />
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={user.name}
        extra={
          <Space>
            <Button
              key="edit"
              icon={<EditOutlined />}
              onClick={() => toggleEditModal(true)}
            >{`${t('actions.edit')}`}</Button>
            {/* <Button
              key="sendInvite"
              type="primary"
              icon={<SendOutlined />}
              onClick={handleSendInvite}
            >{`${t('users.sendInvite')}`}</Button> */}
            <Popconfirm
              title={`${t('general.areYouSure')}`}
              onConfirm={() => handleDeleteUser(user)}
              okText={`${t('general.yes')}`}
              cancelText={`${t('general.no')}`}
            >
              <Button key="delete" danger icon={<DeleteOutlined />}>
                {t('actions.delete')}
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <div>
          {user.avatarUrl ? (
            <img
              src={user.avatarUrl}
              alt={user.name}
              style={{ maxWidth: 200, height: 'auto' }}
            />
          ) : null}
        </div>
        <Descriptions column={1} bordered>
          <Descriptions.Item label={t('users.email')}>
            {user.email}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.phone')}>
            {user.phone}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.dob')}>
            {user.dob && moment(user.dob).format('DD.MM.YYYY.')}
          </Descriptions.Item>
          {isClient(user) && (
            <>
              <Descriptions.Item label={t('users.code')}>
                {user.pin}
              </Descriptions.Item>
              <Descriptions.Item label={t('users.address')}>
                {user.address}
              </Descriptions.Item>
              <Descriptions.Item label={t('users.health')}>
                {user.health}
              </Descriptions.Item>
              <Descriptions.Item label={t('memberships.membership')}>
                {user.userMembership ? (
                  <>
                    <UserMembershipStatus
                      status={user.userMembership?.status}
                      endDate={user.userMembership?.endDate}
                    />
                    <br />
                    <>
                      {user.userMembership?.membership.name}
                      <br />
                      {`${t('memberships.endDate')}: ${formatDate(
                        user.userMembership?.endDate
                      )}`}
                    </>
                    <Button onClick={() => toggleUserMembershipModal(true)}>
                      {t('memberships.editMembership')}
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => toggleUserMembershipModal(true)}>
                    {t('memberships.assignMembership')}
                  </Button>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('plans.plans')}>
                <Space>
                  {user.plans?.map((plan) => (
                    <Link key={plan.id} to={`/plans/${plan.id}`}>
                      {plan.name}
                    </Link>
                  ))}
                  <Button onClick={() => toggleUserPlansModal(true)}>
                    {t('users.editPlans')}
                  </Button>
                </Space>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Card>

      <Card title={`${t('entries.entries')}(${entries.length})`}>
        <EntriesTable entries={entries} showStatus />
      </Card>

      <EditUserMembershipModal
        user={user}
        visible={userMembershipModalVisible}
        onComplete={handleUserMembershipComplete}
        onCancel={() => toggleUserMembershipModal(false)}
      />

      <EditUserPlansModal
        user={user}
        visible={userPlansModalVisible}
        onComplete={handleUserPlansUpdateComplete}
        onCancel={() => toggleUserPlansModal(false)}
      />

      <Modal
        title={`${t('actions.edit')}`}
        open={editModalVisible}
        onCancel={() => toggleEditModal(false)}
        footer={null}
      >
        <UpdateUserForm onComplete={handleUpdateComplete} user={user} />
      </Modal>
    </Space>
  )
}
