import React, { useEffect } from 'react'
import { PrivateRouter } from './PrivateRouter'
import { PublicRouter } from './PublicRouter'
import { useAuth } from '../modules/auth'
import { FullScreenLoader } from '../components/layout/FullScreenLoader'

export const AppRouter = () => {
  const { loggedIn, loading, resetAuthState } = useAuth()
  const url = window.location.href

  const resetState = async () => {
    resetAuthState()
    location.href = url
  }

  useEffect(() => {
    if (
      (url.includes('/auth/reset-password') ||
        url.includes('/auth/forgot-password')) &&
      loggedIn
    ) {
      resetState()
    }
  }, [loggedIn])

  if (loading) {
    return <FullScreenLoader />
  }

  return loggedIn ? <PrivateRouter /> : <PublicRouter />
}
