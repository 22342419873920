import React, { useCallback, useMemo } from 'react'
import { Menu as AntMenu } from 'antd'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Menu = styled(AntMenu)`
  flex: 1;
`

export const MainMenu: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const items = useMemo(
    () => [
      {
        key: '/dashboard',
        label: `Dashboard`,
      },
      {
        key: '/analytics',
        label: `Analytics`,
      },
      {
        key: '/memberships',
        label: t('memberships.memberships'),
      },
      {
        key: '/termsAndConditions',
        label: t('termsAndConditions.termsAndConditions'),
      },
      {
        key: '/plans',
        label: t('plans.plans'),
      },
      {
        key: '/users',
        label: t('users.users'),
      },
      {
        key: '/groupPrograms',
        label: t('groupPrograms.groupPrograms'),
      },
    ],
    []
  )

  const handleClick = useCallback(
    (key: string) => navigate(`${key}`),
    [navigate]
  )

  return (
    <Menu
      mode="horizontal"
      items={items}
      onClick={({ key }) => handleClick(key)}
      selectedKeys={[location.pathname]}
    />
  )
}
