import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useGroupPrograms } from './hooks/useGroupPrograms'
import { GroupProgramsTable } from './GroupProgramsTable'
import { GroupProgramSchedule } from './components/GroupProgramSchedule'

export const GroupProgramsPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { groupPrograms, loading, refetch } = useGroupPrograms()

  return (
    <>
      <Card
        title={t('groupPrograms.groupPrograms')}
        extra={
          <Button
            onClick={() => {
              navigate('/groupPrograms/new')
            }}
            icon={<PlusOutlined />}
          >
            {t('actions.create')}
          </Button>
        }
        loading={loading || !groupPrograms}
      >
        <Row gutter={16}>
          <Col span={8}>
            <GroupProgramsTable
              groupPrograms={groupPrograms || []}
              refetch={refetch}
            />
          </Col>
          <Col span={16}>
            <GroupProgramSchedule groupPrograms={groupPrograms || []} />
          </Col>
        </Row>
      </Card>
    </>
  )
}
