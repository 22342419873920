import React, { useMemo } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { GroupProgram } from '../../../types'

// Props
interface GroupProgramScheduleProps {
  groupPrograms: Omit<GroupProgram, 'id'>[]
}

// Component
export const GroupProgramSchedule: React.FC<GroupProgramScheduleProps> = ({
  groupPrograms,
}) => {
  const { t } = useTranslation()
  const events = useMemo(() => {
    const initialEvents = groupPrograms
      .map((groupProgram) =>
        groupProgram.groups?.map((group) => {
          return group?.groupSlots?.map((groupSlot) => {
            if (groupSlot?.startTime && groupSlot?.endTime) {
              // we use 0 for monday, 1 for tuesday and moment uses 1 for monday, 2 for tuesday
              const dayIndex = groupSlot.dayOfWeek + 1
              const momentDate = moment().isoWeekday(dayIndex)

              return {
                start: momentDate
                  .set({
                    hour: parseInt(groupSlot.startTime.split(':')[0], 0),
                    minute: parseInt(groupSlot.startTime.split(':')[1], 0),
                    second: 0,
                  })
                  .toDate(),
                end: momentDate
                  .set({
                    hour: parseInt(groupSlot.endTime.split(':')[0], 0),
                    minute: parseInt(groupSlot.endTime.split(':')[1], 0),
                    second: 0,
                  })
                  .toDate(),
                title: `${groupProgram.name + ' - ' + group.name}`,
              }
            }
          })
        })
      )
      .flat(2)

    return initialEvents.filter((item) => !!item)
  }, [groupPrograms])

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
      }}
    >
      <FullCalendar
        events={events}
        plugins={[timeGridPlugin]}
        initialView="timeGridWeek"
        firstDay={1}
        headerToolbar={false}
        allDaySlot={false}
        views={{
          timeGrid: {
            slotLabelFormat: {
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
            },
            eventTimeFormat: {
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
            },
            dayHeaderContent: (args) => {
              return t(
                `general.daysOfWeek.${args.date
                  .toLocaleDateString('en-US', {
                    weekday: 'long',
                  })
                  .toLowerCase()}`
              )
            },
          },
        }}
      />
    </div>
  )
}
