import { TFunction } from "i18next";
import moment from "moment";

export const daysOfWeekTranslationKeys = [
  'general.daysOfWeek.monday',
  'general.daysOfWeek.tuesday',
  'general.daysOfWeek.wednesday',
  'general.daysOfWeek.thursday',
  'general.daysOfWeek.friday',
  'general.daysOfWeek.saturday',
  'general.daysOfWeek.sunday',
]

export const formatDate = (date: Date) => moment(date).format('DD.MM.YYYY.')
export const formatDateTime = (date: Date) => moment(date).format('DD.MM.YYYY. HH:mm')
export const getDayOfWeekString = (dayOfWeek: number, t: TFunction) => t(daysOfWeekTranslationKeys[dayOfWeek])