import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Button } from 'antd'
import { t } from 'i18next'
import React from 'react'

type DeleteButtonProps = {
  onDelete: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete }) => {
  return (
    <Popconfirm
      title={`${t('general.areYouSure')}`}
      onConfirm={onDelete}
      okText={`${t('general.yes')}`}
      cancelText={`${t('general.no')}`}
    >
      <Button key="delete" danger icon={<DeleteOutlined />}>
        {t('actions.delete')}
      </Button>
    </Popconfirm>
  )
}
