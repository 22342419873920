import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import { DashboardPage } from '../modules'
import { AnalyticsPage } from '../modules/analytics'
import { CompaniesPage, CompanyDetailsPage } from '../modules/companies'
import { MembershipsPage } from '../modules/memberships'
import { ComponentsPage } from '../modules/plans/ComponentsPage'
import { PlanEditPage } from '../modules/plans/PlanEditPage'
import { PlansPage } from '../modules/plans/PlansPage'
import {
  TermsAndConditionsPage,
  EditTermsAndConditionsPage,
} from '../modules/termsAndConditions'
import { UsersPage } from '../modules/users'
import { PrivacyPage } from '../PrivacyPage'
import { AuthenticatedLayout } from '../components'
import { GroupProgramsPage } from '../modules/groupPrograms/GroupProgramsPage'
import { GroupProgramEditPage } from '../modules/groupPrograms/GroupProgramEditPage'
import { GroupProgramShowPage } from '../modules/groupPrograms/GroupProgramShowPage'

export const PrivateRouter = () => (
  <AuthenticatedLayout>
    <Routes>
      <Route path="/" element={<DashboardPage />} index />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="analytics" element={<AnalyticsPage />} />
      <Route path="companies" element={<CompaniesPage />} />
      <Route path="companies/:id" element={<CompanyDetailsPage />} />
      <Route path="users" element={<UsersPage />} />
      <Route path="users/:id" element={<UsersPage />} />
      <Route path="memberships" element={<MembershipsPage />} />
      <Route path="termsAndConditions" element={<TermsAndConditionsPage />} />
      <Route
        path="termsAndConditions/:id"
        element={<EditTermsAndConditionsPage />}
      />
      <Route path="plans" element={<PlansPage />} />
      <Route path="plans/:id" element={<PlanEditPage />} />
      <Route path="groupPrograms" element={<GroupProgramsPage />} />
      <Route path="groupPrograms/new" element={<GroupProgramEditPage />} />
      <Route path="groupPrograms/:id" element={<GroupProgramShowPage />} />
      <Route path="groupPrograms/:id/edit" element={<GroupProgramEditPage />} />
      <Route path="components" element={<ComponentsPage />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  </AuthenticatedLayout>
)
