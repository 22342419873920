import React, { useState } from 'react'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  notification,
} from 'antd'
import { GroupProgramsApi, GroupsApi, GroupSlotsApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, GroupProgram, GroupSlot } from '../../../types'
import { Errors, TextEditor } from '../../../components'
import { CloseOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { GroupProgramSchedule } from '../components/GroupProgramSchedule'

type GroupProgramFormData = Omit<GroupProgram, 'id'>

interface GroupProgramFormProps {
  groupProgram?: GroupProgram
  refetch?: () => void
}

export const GroupProgramForm: React.FC<GroupProgramFormProps> = ({
  groupProgram,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm<GroupProgramFormData>()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const [scheduleData, setScheduleData] = useState<GroupProgram | undefined>(
    groupProgram
  )

  const [api, contextHolder] = notification.useNotification()

  const openSuccessNotification = () => {
    api.success({
      message: t('general.saveSuccess'),
      duration: 5,
    })
  }

  const handleRemoveGroupSlot = async (
    groupIndex: number,
    groupSlotIndex: number
  ) => {
    const groups = form.getFieldValue('groups') || []
    const groupSlot = groups[groupIndex]?.groupSlots[groupSlotIndex]
    if (groupSlot?.id) {
      try {
        await GroupSlotsApi.destroy(groupSlot.id)
      } catch (err: any) {
        api.error({
          message: t('errors.deleteError'),
          duration: 5,
        })
      }
    }
  }

  const handleRemoveGroup = async (groupIndex: number) => {
    const groups = form.getFieldValue('groups') || []
    const group = groups[groupIndex]
    if (group?.id) {
      await GroupsApi.destroy(group.id)
    }
  }

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: GroupProgramFormData) => {
    setLoading(true)
    let newGroupProgram
    try {
      if (groupProgram?.id) {
        await GroupProgramsApi.update(groupProgram.id, {
          groupProgram: values,
        })

        navigate(`/groupPrograms/${groupProgram.id}`)
      } else {
        newGroupProgram = await GroupProgramsApi.create({
          groupProgram: values,
        })

        form.setFieldsValue(newGroupProgram)
        navigate(`/groupPrograms/${newGroupProgram.id}`)
      }
      setLoading(false)
      openSuccessNotification()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response?.data)
    }
  }

  const getPreviousGroupSlotValue = (
    key: keyof GroupSlot,
    groupIndex: number | string,
    groupSlotIndex: number | string
  ) => {
    if (typeof groupIndex === 'string') {
      groupIndex = parseInt(groupIndex, 10)
    }
    if (typeof groupSlotIndex === 'string') {
      groupSlotIndex = parseInt(groupSlotIndex, 10)
    }

    const formValues = form.getFieldsValue()
    return formValues.groups?.[groupIndex]?.groupSlots?.[groupSlotIndex - 1]?.[
      key
    ]
  }

  const updateSchedule = () => {
    setScheduleData(form.getFieldsValue() as GroupProgram)
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form<GroupProgramFormData>
          name="groupProgramForm"
          onFinish={handleFinish}
          layout="vertical"
          form={form}
          onFieldsChange={updateSchedule}
          initialValues={groupProgram}
        >
          {contextHolder}
          <Errors errors={errors} />

          <Form.Item
            label={t('general.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('forms.errors.requiredField') as string,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label={t('general.description')} name="description">
            <TextEditor theme="snow" />
          </Form.Item>

          <h3>Groups</h3>

          <Form.List name="groups">
            {(fields, { add, remove }) => (
              <div
                style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`${t('groupPrograms.groups.group')} ${
                      field.name + 1
                    }`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          handleRemoveGroup(field.name)
                          remove(field.name)
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label={t('general.name')}
                      name={[field.name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: t('forms.errors.requiredField') as string,
                        },
                      ]}
                      initialValue={`${t('groupPrograms.groups.group')} ${
                        field.name + 1
                      }`}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label={t('groupPrograms.groups.maxParticipants')}
                      name={[field.name, 'maxParticipants']}
                      rules={[
                        {
                          required: true,
                          message: t('forms.errors.requiredField') as string,
                        },
                      ]}
                      initialValue={4}
                    >
                      <Input type="number" min="1" />
                    </Form.Item>

                    <Form.Item label={t('groupPrograms.groupSlots.groupSlots')}>
                      <Form.List name={[field.name, 'groupSlots']}>
                        {(subFields, subOpt) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: 0,
                            }}
                          >
                            {subFields.map((subField) => (
                              <Space key={subField.key}>
                                <Form.Item
                                  label={t(
                                    'groupPrograms.groupSlots.dayOfWeek'
                                  )}
                                  name={[subField.name, 'dayOfWeek']}
                                  style={{ width: '200px' }}
                                  initialValue={0}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'forms.errors.requiredField'
                                      ) as string,
                                    },
                                  ]}
                                >
                                  <Select
                                    options={[
                                      {
                                        label: t('general.daysOfWeek.monday'),
                                        value: 0,
                                      },
                                      {
                                        label: t('general.daysOfWeek.tuesday'),
                                        value: 1,
                                      },
                                      {
                                        label: t(
                                          'general.daysOfWeek.wednesday'
                                        ),
                                        value: 2,
                                      },
                                      {
                                        label: t('general.daysOfWeek.thursday'),
                                        value: 3,
                                      },
                                      {
                                        label: t('general.daysOfWeek.friday'),
                                        value: 4,
                                      },
                                      {
                                        label: t('general.daysOfWeek.saturday'),
                                        value: 5,
                                      },
                                      {
                                        label: t('general.daysOfWeek.sunday'),
                                        value: 6,
                                      },
                                    ]}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label={t(
                                    'groupPrograms.groupSlots.startTime'
                                  )}
                                  name={[subField.name, 'startTime']}
                                  initialValue={getPreviousGroupSlotValue(
                                    'startTime',
                                    field.name,
                                    subField.name
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'forms.errors.requiredField'
                                      ) as string,
                                    },
                                    {
                                      validator: (_, value) => {
                                        // format HH:mm
                                        if (
                                          /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
                                            value
                                          )
                                        ) {
                                          return Promise.resolve()
                                        }

                                        return Promise.reject(
                                          t('forms.errors.invalidTimeFormat')
                                        )
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="12:00" />
                                </Form.Item>
                                <Form.Item
                                  label={t('groupPrograms.groupSlots.endTime')}
                                  name={[subField.name, 'endTime']}
                                  initialValue={getPreviousGroupSlotValue(
                                    'endTime',
                                    field.name,
                                    subField.name
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'forms.errors.requiredField'
                                      ) as string,
                                    },
                                    {
                                      validator: (_, value) => {
                                        // format hh:mm
                                        if (
                                          /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
                                            value
                                          )
                                        ) {
                                          return Promise.resolve()
                                        }

                                        return Promise.reject(
                                          t('forms.errors.invalidTimeFormat')
                                        )
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="13:00" />
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    handleRemoveGroupSlot(
                                      field.name,
                                      subField.name
                                    )
                                    subOpt.remove(subField.name)
                                  }}
                                />
                              </Space>
                            ))}
                            <Button
                              type="dashed"
                              onClick={() => subOpt.add()}
                              block
                            >
                              + {t('groupPrograms.groupSlots.add')}
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button type="dashed" onClick={() => add()} block>
                  + {t('groupPrograms.groups.add')}
                </Button>
              </div>
            )}
          </Form.List>

          <Divider />

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              {t('actions.save')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={12}>
        <GroupProgramSchedule
          groupPrograms={scheduleData ? [scheduleData] : []}
        />
      </Col>
    </Row>
  )
}
