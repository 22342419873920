import { useEffect, useState } from 'react'
import { GroupProgram } from '../../../types'
import { GroupProgramsApi } from '../../api'

export const useGroupPrograms = () => {
  const [loading, setLoading] = useState(false)
  const [groupPrograms, setGroupPrograms] = useState<GroupProgram[]>()

  const fetchGroupPrograms = async () => {
    setLoading(true)
    const data = await GroupProgramsApi.getAll()
    setGroupPrograms(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchGroupPrograms()
  }, [])

  return {
    groupPrograms,
    loading,
    refetch: fetchGroupPrograms,
  }
}
