import { useEffect, useState } from 'react'
import { GroupProgram } from '../../../types'
import { GroupProgramsApi } from '../../api'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const useGroupProgram = (id: string) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [groupProgram, setGroupProgram] = useState<GroupProgram>()

  const fetchGroupProgram = async () => {
    setLoading(true)
    const data = await GroupProgramsApi.get(id)
    setGroupProgram(data)
    setLoading(false)
  }

  const deleteGroupProgram = async (id: string) => {
    await GroupProgramsApi.destroy(id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    navigate('/groupPrograms')
  }

  useEffect(() => {
    fetchGroupProgram()
  }, [])

  return {
    groupProgram,
    loading,
    refetch: fetchGroupProgram,
    deleteGroupProgram,
  }
}
