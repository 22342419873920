// Public Router

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from '../modules/auth/LoginPage'
import { SignupPage } from '../modules/auth/SignupPage'
import { ForgotPasswordPage } from '../modules/auth/ForgotPasswordPage'
import { AuthLayout } from '../components/layout/AuthLayout'
import { ResetPasswordPage } from '../modules/auth/ResetPasswordPage'

export const PublicRouter = () => {
  console.log('PublicRouter render')
  return (
    <AuthLayout>
      <Routes>
        <Route path="auth">
          <Route path="signup" element={<SignupPage />} />
          <Route path="login" element={<LoginPage />} index />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/auth/login" replace={true} />}
        />
      </Routes>
    </AuthLayout>
  )
}
