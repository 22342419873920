import { GroupSignup } from '../../types'
import { apiClient } from './ApiClient'

type GroupSignupIndexParams = {
  groupId: string
}

export const index: (params: GroupSignupIndexParams) => Promise<GroupSignup[]> = async (params) => {
  const response = await apiClient.get<GroupSignup[]>('/group_signups', {
    params
  })
  return response.data
}

export const show: (id: string) => Promise<GroupSignup> = async (id) => {
  const response = await apiClient.get<GroupSignup>(`/group_signups/${id}`)
  return response.data
}

export const create: (data: {
  groupSignup: Omit<GroupSignup, 'id' | 'createdAt' | 'updatedAt' | 'user' | 'group'>
}) => Promise<GroupSignup> = async (data) => {
  const response = await apiClient.post<GroupSignup>('/group_signups', data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<GroupSignup>(`/group_signups/${id}`)
  return response.data
}
